import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';

import Header from '../components/ConsultantHeader';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import LoadingLogo from '../components/LoadingLogo';
import Notification from '../components/Notification';
import { AppState } from '../reducers';
import debug from '../utils/debug';

const styles = (): StyleRules =>
  createStyles({
    noAdmin: {
      height: '50rem',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    noAdminItem: {
      margin: '1%',
    },

    nav: {
      width: '210px',
      padding: '20px',
      textAlign: 'left',
      background: '#673ab7',
    },
    content: {
      overflow: 'hidden',
      margin: '0',
      position: 'relative',
      top: '64px',
      marginBottom: '64px',
      width: '100%',
    },
  });

const ProjectLayout = ({
  classes,
  children,
  verificationHide = false,
}: Props): React.ReactElement => {
  verificationHide;

  debug('Loading admin layout');
  const isLoading = useSelector((state: AppState) => state.loading.isLoading);
  const { isVisible, severity, message } = useSelector((state: AppState) => state.notification);

  if (children) {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link rel="stylesheet" href="https://fonts.loli.net/icon?family=Material+Icons" />
        </Helmet>
        <div style={{ position: 'fixed', zIndex: '9999' }}>
          <Header />
        </div>

        <div className={classes.content}>{children}</div>

        <Footer />
        {isLoading && <Loading />}
        {isVisible && <Notification isVisible={isVisible} severity={severity} message={message} />}
      </>
    );
  }

  return <LoadingLogo />;
};

export interface Props extends WithStyles<typeof styles> {
  children?: React.ReactElement | React.ReactElement[];
  verificationHide?: boolean;
}

export default withStyles(styles)(ProjectLayout);
